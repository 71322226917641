import React from "react";
import Header from "../modules/Header";
import { Box } from "@mui/material";
import BuildingDevicesCoreTable from "../modules/BuildingDevicesCoreTable";
import BuildingDevicesDistroTable from "../modules/BuildingDevicesDistroTable";
import BuildingDevicesAccessSwitchTable from "../modules/BuildingDevicesAccessSwitchTable";
import BuildingDevicesHostTable from "../modules/BuildingDevicesHostTable";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import {
  BuildingDevicesHostDataType,
  BuildingDevicesStructureDataType,
  BuildingNameDataType,
} from "../../types/buildingTypes";
import useRegionQueryParam from "../../hooks/useRegionQueryParam";
import { ApiInstance } from "../../api/api";
import { DevicesHostsDataContext } from "../../context/devicesHostsDataContext";
import { getHostStatus } from "../../utils/host_formatter";

function DevicesPage() {
  const { building } = useParams<BuildingNameDataType>();
  const { selectedRegion } = useRegionQueryParam();
  const [isLoading, setIsLoading] = React.useState(false);
  const [allHosts, setAllHosts] = React.useState<BuildingDevicesHostDataType[]>(
    [],
  );
  const [hosts, setHosts] = React.useState<BuildingDevicesHostDataType[]>([]);
  const [cores, setCores] = React.useState<BuildingDevicesStructureDataType[]>(
    [],
  );
  const [distros, setDistros] = React.useState<
    BuildingDevicesStructureDataType[]
  >([]);
  const [accessSwitches, setAccessSwitches] = React.useState<
    BuildingDevicesStructureDataType[]
  >([]);
  const [loadingState, setLoadingState] = React.useState<Boolean>(false);
  const [apiErrorFlag, setApiErrorFlag] = React.useState(false);

  const getBuildingDevices = async () => {
    try {
      setIsLoading(true);

      const buildingDevices = await ApiInstance.getBuildingDevices(
        building,
        selectedRegion,
      );

      const processedHosts: BuildingDevicesHostDataType[] =
        buildingDevices.host.map((host: BuildingDevicesHostDataType) => {
          const { active, formattedDate } = getHostStatus(host.last_seen);

          return {
            ...host,
            last_seen: formattedDate,
            active,
          };
        });

      setAllHosts(processedHosts);
      setHosts(processedHosts);
      setCores(buildingDevices.core);
      setDistros(buildingDevices.distro);
      setAccessSwitches(buildingDevices.access_switch);

      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      setApiErrorFlag(true);
    }
  };

  React.useEffect(() => {
    getBuildingDevices();
  }, []);

  return (
    <>
      <Header />
      {
        <Box sx={{ margin: "auto", width: "90%" }}>
          <Typography
            sx={{ flex: "1 1 100%" }}
            component="h1"
            variant="h4"
            align="center"
            margin={3}
          >
            Building manager - region: {selectedRegion}, building: {building}
          </Typography>
          <BuildingDevicesCoreTable
            cores={cores}
            isLoading={isLoading}
            apiErrorFlag={apiErrorFlag}
          />
          <BuildingDevicesDistroTable
            distros={distros}
            isLoading={isLoading}
            apiErrorFlag={apiErrorFlag}
          />
          <BuildingDevicesAccessSwitchTable
            accessSwitches={accessSwitches}
            isLoading={isLoading}
            apiErrorFlag={apiErrorFlag}
          />
          <DevicesHostsDataContext.Provider
            value={{ allHosts, hosts, loadingState, setHosts, setLoadingState }}
          >
            <BuildingDevicesHostTable
              hosts={hosts}
              cores={cores}
              distros={distros}
              accessSwitches={accessSwitches}
              selectedRegion={selectedRegion}
              isLoading={isLoading}
              apiErrorFlag={apiErrorFlag}
            />
          </DevicesHostsDataContext.Provider>
        </Box>
      }
    </>
  );
}

export default DevicesPage;
