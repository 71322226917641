import { useLocation } from "react-router-dom";

const useBuildingQueryParam = (): { selectedBuilding: string | null } => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const building = queryParams.get("building");
  return { selectedBuilding: building ? building.toLowerCase() : null };
};

export default useBuildingQueryParam;
