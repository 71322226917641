import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";
import { ApiInstance } from "../../api/api";
import { DeviceARPDataType, CoreNameDataType } from "../../types/buildingTypes";
import { ArpEntriesDataContext } from "../../context/arpEntriesDataContext";
import ARPEntriesSearch from "./ARPEntriesSearch";
import { useParams, useSearchParams } from "react-router-dom";
import useRegionQueryParam from "../../hooks/useRegionQueryParam";
import TablePlaceholder from "../placeholders/TablePlaceholder";
import DeviceProcessingError from "../placeholders/DeviceProcessingError";
import CoreDevicesFilterMenu, {
  DEFAULT_CORE_DEVICES_FILTER_STATE,
} from "../filters/CoreDevicesFilterMenu";
import { formatMacAddress } from "../../utils/mac_formatter";
import useBuildingQueryParam from "../../hooks/useBuildingQueryParam";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCoresCell {
  disablePadding: boolean;
  id: keyof DeviceARPDataType;
  label: string;
  numeric: boolean;
}

const headCoresCells: readonly HeadCoresCell[] = [
  {
    id: "mac_address",
    numeric: false,
    disablePadding: false,
    label: "MAC address",
  },
  {
    id: "ip_address",
    numeric: false,
    disablePadding: false,
    label: "IP address",
  },
  {
    id: "interface",
    numeric: false,
    disablePadding: false,
    label: "Interface",
  },
  {
    id: "vlan",
    numeric: false,
    disablePadding: false,
    label: "VLAN",
  },
  {
    id: "dhcp_static",
    numeric: false,
    disablePadding: false,
    label: "DHCP/Static",
  },
  {
    id: "dhcp_reservation",
    numeric: false,
    disablePadding: false,
    label: "DHCP reservation?",
  },
  {
    id: "dhcp_state",
    numeric: false,
    disablePadding: false,
    label: "DHCP state",
  },
];

interface CoresTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DeviceARPDataType,
  ) => void;
  order: Order;
  orderBy: string;
}

function CoresTableHead(props: CoresTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof DeviceARPDataType) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCoresCells.map((HeadCoresCell) => (
          <TableCell
            key={HeadCoresCell.id}
            align={HeadCoresCell.numeric ? "right" : "left"}
            padding={HeadCoresCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === HeadCoresCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === HeadCoresCell.id}
              direction={orderBy === HeadCoresCell.id ? order : "asc"}
              onClick={createSortHandler(HeadCoresCell.id)}
            >
              {HeadCoresCell.label}
              {orderBy === HeadCoresCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface TableToolbarProps {
  title: string;
  search: boolean;
  navigationFilter?: boolean;
  navigationFilterName?: string;
  navigationFilterValue?: string;
}

function TableToolbar(props: TableToolbarProps) {
  const {
    title,
    search,
    navigationFilter,
    navigationFilterName,
    navigationFilterValue,
  } = props;
  const [filterInput, setFilterInput] = React.useState(
    DEFAULT_CORE_DEVICES_FILTER_STATE,
  );

  return (
    <Toolbar>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        {title}:
      </Typography>
      {search && <ARPEntriesSearch />}
      <CoreDevicesFilterMenu
        filterState={filterInput}
        setFilterState={setFilterInput}
        navigationFilter={navigationFilter}
        navigationFilterName={navigationFilterName}
        navigationFilterValue={navigationFilterValue}
      />
    </Toolbar>
  );
}

interface FilterMap {
  [key: string]: string;
}

export default function CoreDevicesTable() {
  const { core } = useParams<CoreNameDataType>();
  const { selectedBuilding } = useBuildingQueryParam();
  const { selectedRegion } = useRegionQueryParam();
  const [isLoading, setIsLoading] = React.useState(false);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof DeviceARPDataType>("ip_address");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [allARPEntries, setAllARPEntries] = React.useState<DeviceARPDataType[]>(
    [],
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [arpEntries, setARPEntries] = React.useState<DeviceARPDataType[]>([]);
  const [apiErrorFlag, setApiErrorFlag] = React.useState<Boolean>(false);
  const [loadingState, setLoadingState] = React.useState<Boolean>(false);
  const [searchParams] = useSearchParams();
  const [navigationFilter, setNavigation] = React.useState(false);
  const [navigationFilterName, setNavigationFilterName] =
    React.useState<string>("");
  const [navigationFilterValue, setNavigationFilterValue] =
    React.useState<string>("");

  const filterMap: FilterMap = {
    "filter[ip_address]": "ip_address",
    "filter[mac_address]": "mac_address",
  };

  const extractNavigationFilterParams = (
    searchParams: URLSearchParams,
    filterMap: FilterMap,
  ) => {
    for (const [key, value] of Array.from(searchParams.entries())) {
      if (key.startsWith("filter") && filterMap[key]) {
        return {
          navigationFilter: true,
          navigationFilterName: filterMap[key],
          navigationFilterValue: value,
        };
      }
    }

    return {
      navigationFilter: false,
      navigationFilterName: "",
      navigationFilterValue: "",
    };
  };

  const getCoreDevices = async () => {
    try {
      setIsLoading(true);
      const { navigationFilter, navigationFilterName, navigationFilterValue } =
        extractNavigationFilterParams(searchParams, filterMap);

      const buildingDevices = await ApiInstance.getCoreDevices(
        core,
        "juniper",
        selectedBuilding,
        selectedRegion,
      );

      setAllARPEntries(buildingDevices.core_data);
      setARPEntries(buildingDevices.core_data);
      setIsLoading(false);
      setNavigation(navigationFilter);
      setNavigationFilterName(navigationFilterName);
      setNavigationFilterValue(navigationFilterValue);
    } catch (e: any) {
      setApiErrorFlag(true);
    }
  };

  React.useEffect(() => {
    getCoreDevices();
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DeviceARPDataType,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arpEntries.length) : 0;

  const coreRows = React.useMemo(
    () =>
      stableSort(arpEntries, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, arpEntries],
  );

  const column_labels = headCoresCells.map((item) => item.label);

  return (
    <ArpEntriesDataContext.Provider
      value={{
        allARPEntries,
        arpEntries,
        loadingState,
        setARPEntries,
        setLoadingState,
      }}
    >
      <Box sx={{ width: "100%", margin: "5pt" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableToolbar
            title={"Selected core"}
            search={true}
            navigationFilter={navigationFilter}
            navigationFilterName={navigationFilterName}
            navigationFilterValue={navigationFilterValue}
          />
          {isLoading ? (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <TablePlaceholder rows={15} columns={column_labels} />
            </Box>
          ) : (
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <CoresTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {coreRows.map((row, index) => {
                    const labelId = `arp-entries-table-checkbox-${index}`;

                    return (
                      <TableRow hover sx={{ cursor: "pointer" }}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normal"
                        >
                          {formatMacAddress(row.mac_address)}
                        </TableCell>
                        <TableCell>{row.ip_address}</TableCell>
                        <TableCell>{row.interface}</TableCell>
                        <TableCell>{row.vlan}</TableCell>
                        <TableCell>{row.dhcp_static}</TableCell>
                        <TableCell>{row.dhcp_reservation}</TableCell>
                        <TableCell>{row.dhcp_state}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  {apiErrorFlag && (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <DeviceProcessingError />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <TablePagination
            rowsPerPageOptions={[
              15,
              25,
              50,
              { value: arpEntries.length, label: "All" },
            ]}
            component="div"
            count={arpEntries.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage="Rows per table:"
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </ArpEntriesDataContext.Provider>
  );
}
