/**
 * Identify a status for host.
 * @param {string} lastSeen
 * @returns {boolean}
 */
export const isActive = (lastSeen: string): boolean => {
  const lastSeenDate = new Date(lastSeen);
  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(currentDate.getDate() - 7);

  return lastSeenDate >= oneWeekAgo;
};

/**
 * Format a date into US locale string format.
 * @param {string} date
 * @returns {string}
 */
export const formatDate = (date: string): string => {
  const lastSeenDate = new Date(date);
  return lastSeenDate.toLocaleString("en-US");
};

/**
 * Determine user status and format the `last_seen` date.
 * @param {string} lastSeen
 * @returns {Object}
 */
export const getHostStatus = (lastSeen: string) => {
  const hostStatus = isActive(lastSeen);
  const formattedDate = formatDate(lastSeen);

  return { active: hostStatus, formattedDate };
};
